import React, { useEffect, useState } from "react";
import { Row, Col, Card, Badge, Carousel, Menu, Breadcrumb, Dropdown, Button, Spin, notification } from "antd";
import { FaCrown, FaHeart, FaKickstarter, FaRegHeart, FaStar, FaStarHalfAlt, FaThumbsUp, FaWhatsapp } from "react-icons/fa";
import { BiLeftArrow, BiPhoneCall, BiRightArrow } from "react-icons/bi";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getPremiumBusiness } from "../../../utils/API";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusCircleIcon } from "@heroicons/react/24/outline";


const BusinessListings = () => {
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [businessdata, setBusinessData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [verifiedFilter, setVerifiedFilter] = useState(false);
  const [trustedFilter, setTrustedFilter] = useState(false);
  const location = useLocation(); // Initialize useLocation
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const [wishlist, setWishlist] = useState<any>([]);
  const state = searchParams.get("state"); // Retrieve state parameter
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';

  // const [isNumberVisible, setIsNumberVisible] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [visibleNumbers, setVisibleNumbers] = useState<{ [key: string]: boolean }>({});

  const handleToggleNumberVisibility = (slug: string) => {
    setVisibleNumbers((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  const mobileNumbersArray = mobileNumber ? [mobileNumber] : [];

  const handleGetBusiness = async (page: any) => {
    try {
      setLoading(true);
      const res = await getPremiumBusiness(page, category, state);
      setBusinessData((prevData: any) => [...prevData, ...res.data]);
      setMobileNumber(res.data.mobileNumber);
      setTotalPages(res.totalPages);
      setCurrentPage(res.currentPage);
    } catch (error) {
      console.error("Failed to fetch business data", error);
    } finally {
      setLoading(false);
    }
  };

  const filterBusinessData = (data: any) => {
    return data.filter((business: any) => {
      if (verifiedFilter && business.isVerified !== 2) return false;
      if (trustedFilter && business.isTrusted !== 2) return false;
      return true;
    });
  };

  useEffect(() => {
    // Load wishlist from local storage
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist") || "[]");
    setWishlist(savedWishlist);
  }, []);

  useEffect(() => {
    // Save wishlist to local storage
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const isInWishlist = (business: any) => {
    return wishlist.some((item: any) => item.slug === business.slug);
  };

  const filteredData = filterBusinessData(businessdata);


  useEffect(() => {
    handleGetBusiness(currentPage);
  }, []);


  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentPage(prevPage => prevPage + 1);
      setLoading(false);
    }, 5000);
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <BiRightArrow
        className={className}
        style={{
          ...style,
          fontSize: "24px",
          color: "#FFF",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          position: "absolute",
          right: "0px",
          top: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          opacity: 0.5,
          transform: "translateY(-50%)",
          zIndex: 2,
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <BiLeftArrow
        className={`${className} ${showRightArrow ? "block" : "hidden"}`}
        style={{
          ...style,
          fontSize: "24px",
          color: "#FFF",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          position: "absolute",
          left: "0px",
          top: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          opacity: 0.5,
          transform: "translateY(-50%)",
          zIndex: 2,
        }}
        onClick={onClick}
      />
    );
  };

  const handlenavigate = (slug: any) => {
    navigate(`/business/${slug}`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );


  const sortmenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setVerifiedFilter(!verifiedFilter)}>
        {verifiedFilter ? "Unfilter Verified" : "Filter Verified"}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setTrustedFilter(!trustedFilter)}>
        {trustedFilter ? "Unfilter Trusted" : "Filter Trusted"}
      </Menu.Item>
      <Menu.Item key="3" onClick={() => {
        setVerifiedFilter(false);
        setTrustedFilter(false);
      }}>
        Reset Filters
      </Menu.Item>
    </Menu>
  );

  const handleAddToWishlist = (business: any) => {
    setWishlist((prevWishlist: any) => [...prevWishlist, business]);
    notification.success({ message: "Business added to wishlist" });
  };

  const handleRemoveFromWishlist = (business: any) => {
    setWishlist((prevWishlist: any) => prevWishlist.filter((item: any) => item.slug !== business.slug));
  };
  const handleEnquiryRedirect = () => {
    navigate("/contact-us");
  };
  return (
    <div className="mt-20">
      <div className="xl:ml-56 xl:mr-56 md:mt-24  md:ml-8">
        <h2 className="text-xl font-bold mt-2">Top Businesses</h2>
        <Breadcrumb
          items={[
            { title: <Link to="/">Home</Link> },
            { title: <Link to="/listing">Application Center</Link> },
            { title: <Link to="/listing">Application List</Link> },
            { title: "Businesses" },
          ]}
        />

        <div className="flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-4   mt-2">
          <Dropdown overlay={sortmenu} placement="bottomLeft" arrow>
            <Button className="w-full sm:w-auto mb-2">Sort by</Button>
          </Dropdown>
          <Button icon={<FaCrown />} className="w-full sm:w-auto text border mb-2">
            Top Rated
          </Button>
          <Button icon={<FaKickstarter />} className="w-full sm:w-auto text border mb-2">
            Quick Response
          </Button>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button className="w-full sm:w-auto mb-2">Properties Served</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button className="w-full sm:w-auto mb-2">Commercial Type</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button className="w-full sm:w-auto mb-2">Residential Type</Button>
          </Dropdown>
          <Button className="w-full sm:w-auto text border mb-2">For Rental</Button>
        </div>

      </div>
      <InfiniteScroll
        dataLength={businessdata.length}
        next={handleLoadMore}

        hasMore={currentPage < totalPages}
        loader={<Spin />}
      >
        {filteredData.length > 0 ? (
          filteredData.map((business: any, index: any) => (
            <Card key={index} className="xl:ml-56 xl:mr-56 mt-8">
              <Row gutter={8}>
                <Col xs={24} sm={24} md={7} lg={5} xl={5} className="cursor-pointer" onClick={() => handlenavigate(business.slug)}>
                  <Carousel
                    dots={false}
                    arrows
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    draggable
                    beforeChange={(from, to) => {
                      if (from === 1 && to === 2) {
                        setShowRightArrow(true);
                      }
                    }}
                  >
                    {business.galleryImage.map((image: any, imgIndex: any) => (
                      <img
                        className="w-44 h-48 xl:h-48 md:h-60 lg:h-48 rounded-lg"
                        src={`${imageUrl}${image}`}
                        alt={`Carousel ${imgIndex}`}
                      />
                    ))}
                  </Carousel>
                </Col>
                <Col xs={24} sm={24} md={17} lg={19} xl={19}>
                  <div className="flex justify-between items-center md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0">
                    <div className="flex items-center">
                      <FaThumbsUp className="text-yellow-500" size={20} />
                      <p className="text-black pl-2 text-xl xl:text-xl hover:text-yellow-500 font-bold cursor-pointer" onClick={() => handlenavigate(business.slug)}>
                        {business.businessName}
                      </p>
                    </div>
                    <div>
                      {isInWishlist(business) ? (
                        <FaHeart
                          className="text-yellow-500 cursor-pointer"
                          size={20}
                          onClick={() => handleRemoveFromWishlist(business)}
                        />
                      ) : (
                        <FaRegHeart
                          className="text-yellow-500 cursor-pointer"
                          size={20}
                          onClick={() => handleAddToWishlist(business)}
                        />
                      )}

                    </div>
                  </div>
                  <div className="flex flex-wrap items-center pt-2">
                  <span className="text-sm rounded-md pl-1 pr-1 tracking-widest bg-yellow-700 text-black">
  <span className="text-lg font-semibold pl-2 pr-2">
    {business.averageRating != null ? parseFloat(business.averageRating).toFixed(1) : '0'}
  </span>
</span>
<div className="flex pl-2">
  {[...Array(5)].map((_, i) => {
    const fullStars = Math.floor(business.averageRating); // Full stars count
    const hasHalfStar = business.averageRating % 1 !== 0; // Check for half star

    if (i < fullStars) {
      // Full stars
      return (
        <FaStar
          key={i}
          size={20}
          className="pr-0.5 text-yellow-400"
        />
      );
    } else if (i === fullStars && hasHalfStar) {
      // Half star
      return (
        <FaStarHalfAlt
          key={i}
          size={20}
          className="pr-0.5 text-yellow-400"
        />
      );
    } else {
      // Empty stars
      return (
        <FaStar
          key={i}
          size={20}
          className="pr-0.5 text-gray-300"
        />
      );
    }
  })}
</div>



                    {/* <p className="text-black pl-2 text-sm font-semibold">
                      20 ratings 
                    </p> */}
                    <div className="block sm:inline mt-1">
                      {business.isVerified === 2 && (
                        <Badge className="text-md font rounded-md pl-2 ml-2 pr-1 px-2 py-1 bg-green-300 text-black">
                          Verified
                        </Badge>
                      )}
                      {business.isTrusted === 2 && (
                        <Badge className="text-md font rounded-md pl-2 py-1 ml-2 pr-1 px-2 bg-yellow-200 text-black">
                          Trusted
                        </Badge>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center pt-3">
                    <EnvironmentOutlined size={20} className="pr-2 text-yellow-500" />
                    <p>{`${business.buildingName}, ${business.streetName}, ${business.state} ${business.pincode}  `}</p>
                  </div>
                  <div className="flex items-center pt-3">
                    <Badge className="text-sm rounded-md pl-1 pr-1 px-2 py-1 bg-slate-200 text-gray-800">
                      {business.categoryId[0].name}
                    </Badge>
                  </div>
                  <div className="flex items-center flex-wrap pt-3">
                    <Col
                      xs={24}
                      sm={8}
                      md={8}
                      lg={6}
                      xl={7}
                      xxl={6}
                      className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
                    >
                      <button
                        className="flex items-center font-bold justify-center space-x-2 py-1.5 rounded-md bg-black text-yellow-400 w-full"
                        onClick={() => handleToggleNumberVisibility(business.slug)}
                      >
                        <BiPhoneCall />
                        <span>
                          {visibleNumbers[business.slug] ? business.mobileNumber : "Show Number"}
                        </span>
                      </button>
                    </Col>
                    <Col
                      xs={24}
                      sm={8}
                      md={8}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
                    >
                      <button className="flex items-center justify-center font-bold space-x-2 px-4 py-1.5 rounded-md bg-yellow-700 text-black w-full"
                        onClick={handleEnquiryRedirect}
                      >
                        <span>Send Inquiry</span>
                      </button>
                    </Col>
                    {/* <Col
                      xs={24}
                      sm={8}
                      md={8}
                      lg={5}
                      xl={3}
                      xxl={3}
                      className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
                    >
                      <button className="flex items-center justify-center font-bold space-x-2 px-2 py-1 rounded-md bg-white border-1 border-gray-300 text-gray-700 w-full">
                        <FaWhatsapp size={18} color="green" />
                        <span>Chat</span>
                      </button>
                    </Col> */}
                  </div>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          
          <div className="flex justify-center items-center h-48">
            {loading ? <Spin size="large" /> : <p className="text-xl text-gray-400" > No Businesses found.</p>}
          </div>

        )}
        
      </InfiniteScroll>


      {/* Load more button */}
      {currentPage < totalPages && (
        <div className="flex justify-center mt-4">
          <Button onClick={handleLoadMore} loading={loading}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default BusinessListings;
