import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Modal, Row, Spin, Switch, Table, message, Image, InputRef, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deletebusiness, BusinessVerify, BusinessTrusted, updateBusinessStatus, getBusinesuser } from '../../../../utils/API';
import { FaEdit } from 'react-icons/fa';


interface Customer {
    _id: number;
    id: number;
    businessName: string;
    area: string;
    email: string;
    mobileNumber: string;
    isVerified: boolean;
    galleryImage: string[];
}

const Businessindex: React.FC = () => {
    const [customerData, setCustomerData] = useState<Customer[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';
    const navigate = useNavigate();
    const authData = localStorage.getItem('authData');
    let userId = '';
    if (authData) {
        const parsedAuthData = JSON.parse(authData);
        userId = parsedAuthData.user._id;
    }

    const FetchCustomerData = (page: number) => {
        setLoading(true);
        getBusinesuser(userId, page, "", "")
            .then((response: any) => {
                console.log(response.data);
                const filteredCustomerData = response.data.filter((customer: any) => !customer.isDeleted);
                setCustomerData(filteredCustomerData);
                setTotalPages(response.totalPages);
                setLoading(false);
            })
            .catch((error: any) => {
                message.error('Network error. Please try again.');
                setLoading(false);
            });
    };

    useEffect(() => {
        FetchCustomerData(currentPage);
    }, [currentPage]);





    const BusinessVerifyd = (record: Customer) => {
        BusinessVerify(record._id)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success(response.data.message);
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const Businesstrusted = (record: Customer) => {
        BusinessTrusted(record._id)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success(response.data.message);
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const updateIsHome = (record: Customer, checked: boolean) => {
        updateBusinessStatus(record._id, { isHome: checked ? 1 : 0 })
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Status updated successfully");
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const updateIsFeatured = (record: Customer, checked: boolean) => {
        updateBusinessStatus(record._id, { isFeatured: checked ? 1 : 0 })
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Status updated successfully");
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };


    const columns = [
        {
            title: (
                <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
                    Images
                </div>
            ),
            dataIndex: "galleryImage",
            key: "galleryImage",
            render: (galleryImage: string[]) => (
                <div>
                    {galleryImage && galleryImage.length > 0 && (
                        <Image
                            src={`${imageUrl}${galleryImage[0]}`} // Adjust path as needed
                            alt={`Thumbnail 1`}
                            className='rounded-md'
                            style={{ width: "80px", height: "60px", marginRight: "5px" }}
                        />
                    )}
                </div>
            ),
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Name</div>,
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Location</div>,
            dataIndex: 'city',
            key: 'city',
            render: (text: any, record: any) => {
                return <div>{record.city} , {record.state}</div>;
            }
        },

        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Contact</div>,
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Action</div>,
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <div className="d-flex ">
                        <a
                            className="text-yellow-500 cursor-pointer hover:text-yellow-600"
                            onClick={() => navigate(`/business/${record._id}/mywebsite`)}
                        >
                            My Website
                        </a>
                    </div>
                );
            },
        }
    ];

    return (
        <>
            <Row className="m-2" align={"middle"}>
                <Col

                    xs={24}
                    sm={24}
                    md={9}
                    xl={6}
                    xxl={6}
                    className="d-flex justify-content-start font-bold"
                >
                    <h2 className="text-2xl">Businesses</h2>
                </Col>
                <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
                </Col>
            </Row>
            <Row>
                <Col
                    style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
                    xs={24}
                    sm={24}
                    md={24}
                    xl={24}
                    xxl={24}
                >
                    <Card className="bg-white border " style={{ borderRadius: "12px" }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Spin spinning={loading}>
                                <div style={{ overflowX: 'auto' }}>
                                    <Table
                                        columns={columns}
                                        dataSource={customerData}
                                        scroll={{ x: true }}
                                        pagination={{
                                            current: currentPage,
                                            total: totalPages * 7,
                                            onChange: (page) => setCurrentPage(page),
                                        }}
                                    />
                                </div>
                            </Spin>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Businessindex;